.user-message-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 17px;
    color: white;
    margin-top: 32px;
}
.time-user {
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family : "Gilroy-Light, sans-serif";
    width: 50px;
    top: -17px;
    right: 10px;
}
.chat-text-user {
    text-align: end;
    border-radius: 20px 20px 0px 20px;
    border: 1px solid #00aefe;
    background: #000;
    max-width: 363px;
    width: fit-content;
    padding: 13px 21px;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    transform: translateX(20px);
    opacity: 0;
    animation-name: message-appear;
    animation-duration: .7s;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
}
@keyframes message-appear {
    0%{
        transform: translateX(20px);
        opacity: 0;
    }
    100%{
        transform: translateX(0px);
        opacity: 100;
    }
}