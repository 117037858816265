.bot-message-container {
    display: flex;
    align-items: flex-start;
}
.time-bot {
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: -15px;
    left: 50px;
}
.chat-text {
    margin-left: 8px;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    max-width: 363px;
    width: fit-content;
    letter-spacing: 1px;
    padding: 16px 25px 13px 25px;
    align-items: flex-start;
    border-radius: 0px 20px 20px 20px;
    background: #0159FD;
    transform: translateX(-20px);
    opacity: 0;
    animation-name: message-appear-2;
    animation-duration: .9s;
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
}
.dots-loading {
    display: flex;
    column-gap: 4px;
}
.dot-load {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #FFF;
    transform: translateY(0);
}
#do1L1 {
    animation-name: dot-up;
    /* animation-delay: 0s; */
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    /* animation-fill-mode: forwards; */
    animation-iteration-count: infinite;
}
#do1L2 {
    animation-name: dot-up;
    animation-delay: .2s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    /* animation-fill-mode: forwards; */
    animation-iteration-count: infinite;
}
#do1L3 {
    animation-name: dot-up;
    animation-delay: .4s;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    /* animation-fill-mode: forwards; */
    animation-iteration-count: infinite;
}
@keyframes dot-up {
    0% {
        transform: translateY(0);
    }
    70%{
        transform: translateY(-4px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes message-appear-2 {
    0%{
        transform: translateX(-20px);
        opacity: 0;
    }
    100%{
        transform: translateX(0px);
        opacity: 100;
    }
}